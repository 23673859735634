import { Home } from 'layouts';
import { HomeIndexPropTypes } from 'layouts/Home';
import { getAllVideos, getFeaturedReel } from 'lib/api/videos';
import { getAllGalleries } from 'lib/api/galleries';

export async function getStaticProps() {
    let props: HomeIndexPropTypes = {};

    props.videos = getAllVideos()
        .map((video) => ({
            title: video.title,
            desc: video.desc,
            videoLink: video.videoLink,
            previewImage: {
                url: video.previewImage,
            },
        }))
        .slice(0, 9);

    props.photos = getAllGalleries().slice(0, 9);

    props.featuredVideo = getFeaturedReel();

    return {
        props,
    };
}

export default Home;
